import { API, removeBlankAttributes } from "@project/common"
import {
  EmploymentCertificateListApiValues,
  UserWorkInformationCreateEditRequestType,
  UserWorkInformationStatusRequestType,
} from "../types"

const BASE_URL = "/user-work-information"
export const createUserWorkInformation = (
  values: UserWorkInformationCreateEditRequestType,
) => {
  return API.post(`${BASE_URL}`, values)
}
export const createUserWorkInformationStatus = (
  values: UserWorkInformationStatusRequestType,
) => {
  return API.post(`${BASE_URL}/status`, values)
}
export const getAllUserWorkInformationStatus: any = (args) => {
  const params = {
    page: args?.page || 1,
    pageSize: args?.pageSize,
  }
  return API.get(`${BASE_URL}/status?user_id=${args?.user_id}`, { params })
}

export const fetchAllEmploymentCertificate = async (args: any) => {
  const params = {
    ...args,
    facilityIds: args.facilityIds?.join(","),
  }
  const updatedParams = removeBlankAttributes(params)
  const result: EmploymentCertificateListApiValues = await API.get(
    `${BASE_URL}`,
    {
      params: updatedParams,
    },
  )

  return result
}

export const getAllPlaceOfEmployment: any = (args) => {
  const params = {
    pageSize: args?.pageSize,
  }
  return API.get(`/employment-company`, { params })
}
export const deleteEmploymentCertificate: any = (args: any) => {
  return API.delete(`/user-work-information/status/${+args}`)
}

export const getAllUserWorkInformation: any = (args) => {
  const params = {
    pageSize: args?.pageSize,
  }
  return API.get(`${BASE_URL}/user/${args?.user_id}`, { params })
}
