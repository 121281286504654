import { Button, Table, theme } from "@project/common"
import { Flex, Popconfirm, Typography, notification } from "antd"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import {
  FULL_DATE_FORMAT,
  METHOD_OF_CONTACT,
  PAGE_SIZE,
  STATUS_OF_USER,
} from "../../../constants"
import { deleteEmploymentCertificate } from "../../../services/employmentCertificate.services"
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .status_table_header {
    border-bottom: 1px solid ${theme.colors.border};
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
  }
  .line-break-text {
    white-space: pre-line;
    word-break: break-all;
  }
  .preview {
    font-size: 14px;
    color: #0782c8;
    cursor: pointer;
    text-decoration: underline;
  }
`

export const EmploymentCertificateStatusList = ({
  dataSource,
  page,
  handlePaginationChange,
  total,
  isLoading,
  refetch,
  refetchBasicInfo,
}: {
  dataSource: any[]
  page: number
  handlePaginationChange: any
  total: number
  isLoading?: boolean
  refetch?: any
  refetchBasicInfo?: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { mutate: handleDelete, isLoading: isDeleting } = useMutation({
    mutationFn: deleteEmploymentCertificate,
    onSuccess: () => {
      notification.success({
        message: t("Deleted Successfully"),
      })
      refetchBasicInfo()
      refetch()
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const columns: ColumnsType<any> = [
    {
      key: "1",
      align: "center",
      title: t("Delete"),
      width: 150,
      render: (row: any) => {
        return (
          <Flex justify={"center"}>
            <Popconfirm
              title={t("Deleting.Is that OK?")}
              onConfirm={() => handleDelete(row?.id)}
              okText={t("OK")}
              cancelText={t("Cancel")}
              okButtonProps={{ size: "middle" }}
              cancelButtonProps={{ size: "middle" }}
            >
              <Button
                btnText={t("Delete")}
                type={"link"}
                padding={"0"}
                width={"fit-content"}
                style={{
                  textDecoration: "underline",
                  color: theme.colors.error,
                }}
                hoverBorderColor={"none"}
              />
            </Popconfirm>
          </Flex>
        )
      },
    },
    {
      key: "2",
      align: "center",
      width: 100,
      title: t("Certificate"),
      render: (row) =>
        row?.employment_certificate ? (
          <div
            className={"preview"}
            onClick={() => window.open(row?.employment_certificate, "_blank")}
          >
            {t("Display")}
          </div>
        ) : null,
    },
    {
      key: "2",
      align: "center",
      width: 130,
      title: t("Contacted date"),
      render: (row) => (
        <Typography.Text
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {dayjs(row?.contacted_date).format(FULL_DATE_FORMAT)}
        </Typography.Text>
      ),
    },
    {
      key: "2",
      align: "center",
      width: 200,
      title: t("Place of employment"),
      render: (row) => (
        <Typography.Text
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {row?.employment_place}
        </Typography.Text>
      ),
    },
    {
      key: "3",
      align: "center",
      width: 300,
      title: t("Status of user"),
      render: (row) => (
        <Typography.Text>
          {STATUS_OF_USER?.find((v) => v?.value === row?.status_of_user)
            ?.label || ""}
        </Typography.Text>
      ),
    },
    {
      key: "4",
      align: "center",
      width: 515,
      title: t("Remarks"),
      render: (row) => (
        <Typography.Text className={"line-break-text "}>
          {row?.remarks || ""}
        </Typography.Text>
      ),
    },
    {
      key: "5",
      align: "center",
      width: 200,
      title: t("Method of contact"),
      render: (row) => (
        <Typography.Text>
          {METHOD_OF_CONTACT?.find((v) => v?.value === row?.method_of_contact)
            ?.label || ""}
        </Typography.Text>
      ),
    },
  ]
  return (
    <Wrapper>
      <div className={"status_table_header"}>{t("Status list")}</div>
      <Table
        columns={columns}
        scroll={{ x: 900 }}
        current={+page}
        total={total}
        pageSize={PAGE_SIZE}
        onChange={handlePaginationChange}
        dataSource={dataSource || []}
        showPaginationOf={"both"}
        loading={isLoading || isDeleting}
      />
    </Wrapper>
  )
}
