import {
  Button,
  Card,
  DatePicker,
  Grid,
  ImageUpload,
  InputAreaField,
  InputField,
  LAST_STATUS_OF_USER,
  PLACE_OF_EMPLOYMENT,
  RadioGroup,
  SelectInput,
  scrollToFirstErrorField,
  scrollToSelectedElement,
  theme,
} from "@project/common"
import { Flex, notification } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useFormik } from "formik"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import { METHOD_OF_CONTACT, PAGE_SIZE } from "../../../constants"
import {
  createUserWorkInformationStatus,
  getAllUserWorkInformationStatus,
} from "../../../services/employmentCertificate.services"
import {
  EmploymentCertificateStatusSchema,
  UserWorkInformationStatusRequestType,
} from "../../../types"
import { EmploymentCertificateStatusList } from "./EmploymentCertificateStatusList"
interface IProps {
  user_id?: string | number
  refetchBasicInfo?: () => void
}

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .preview-button {
    position: absolute;
    right: 0;
    @media screen and (max-width: 540px) {
      bottom: 0;
      left: 0;
      margin-left: -15px !important;
    }
    button {
      border: none;
      background: none !important;
      color: #0782c8;
    }
  }
  .image-upload {
    @media screen and (max-width: 540px) {
      margin-bottom: 20px !important;
    }
  }
`
const PlaceOfEmploymentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .ant-radio-group {
    display: flex;
    align-items: center;
  }
  .input-field {
    width: auto;
  }
  .date-picker {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
const CertificateError = styled.div`
  color: ${theme.colors.alert};
  font-size: 13px;
`
export const EmploymentCertificateStatus: React.FC<IProps> = ({
  user_id,
  refetchBasicInfo,
}): JSX.Element => {
  const { t } = useTranslation()
  const [page, setPage] = useState<any>()
  const contentRef = useRef<HTMLDivElement>(null)
  const [isFileSubmitting, setIsFileSubmitting] = useState(false)
  const router = useRouter()
  //fetch work status information
  const {
    data: response,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["user-work-status-information", page],
    queryFn: () =>
      getAllUserWorkInformationStatus({
        page,
        pageSize: PAGE_SIZE,
        user_id: +user_id,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  //create status
  const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: createUserWorkInformationStatus,
    onSuccess: () => {
      notification.success({
        message: t("Created Successfully"),
      })
      refetchBasicInfo()
      refetch()
      resetForm()
      scrollToSelectedElement(contentRef)
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const initialValues: UserWorkInformationStatusRequestType = {
    user_id: +user_id,
    contacted_date: null,
    status_of_user: 4,
    remarks: "",
    method_of_contact: null,
    employment_certificate: null,
    employment_place: "",
    place_of_employment: 1,
    employment_place_date: null,
  }
  const { values, handleSubmit, setFieldValue, errors, resetForm, ...formik } =
    useFormik({
      initialValues: initialValues,
      validationSchema: EmploymentCertificateStatusSchema,
      onSubmit: (formValues) => mutate(formValues),
    })

  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(errors)
  }, [formik.submitCount, formik.isValid])
  // create work  status information
  const filterPage = user_id ? "edit" : "add"
  const handlePaginationChange = (page: number) => {
    setPage(page || 1)
    scrollToSelectedElement(contentRef)
    router.push(
      {
        pathname: `employment-certificate/${filterPage}`,
        query: {
          page,
        },
      },
      undefined,
      { scroll: false },
    )
  }

  return (
    <Card title={t("Confirm the status of employment")}>
      <Flex component={"form"} vertical gap={16}>
        <div>
          <Grid
            background
            labelContent={t("Contacted date")}
            padding={"15px"}
            required
            labelSpan={6}
          >
            <DatePicker
              name={"contacted_date"}
              placeholder={t("Please select")}
              date={
                values?.contacted_date ? dayjs(values?.contacted_date) : null
              }
              onDateChange={(date: Dayjs) =>
                setFieldValue(
                  "contacted_date",
                  dayjs(date).format("YYYY-MM-DD"),
                )
              }
              error={
                !!formik?.touched?.contacted_date && !!errors?.contacted_date
                  ? errors?.contacted_date
                  : null
              }
            />
          </Grid>
          <Grid
            background
            labelContent={t("Upload certificate of employment")}
            padding={"15px"}
            labelSpan={6}
            textAlign={"left"}
          >
            <ImageWrapper>
              <div className={"image-upload"}>
                <ImageUpload
                  onChange={(data) =>
                    setFieldValue("employment_certificate", data)
                  }
                  isLoading={setIsFileSubmitting}
                  shape={"round"}
                  otherType={"draft"}
                  iconType={"upload"}
                  btnText={t("upload")}
                  btnDefault={false}
                  uploadHint={t("(PDFのみ)")}
                  currentImage={values?.employment_certificate}
                  isImg={false}
                  acceptType={".pdf"}
                />
                <CertificateError>
                  {!!formik?.touched?.employment_certificate &&
                  !!errors?.employment_certificate
                    ? errors?.employment_certificate
                    : null}
                </CertificateError>
              </div>
              {values?.employment_certificate && (
                <div className={"preview-button"}>
                  <Button
                    btnText={t("Preview  Certificate")}
                    onClick={() =>
                      window.open(values?.employment_certificate, "_blank")
                    }
                  />
                </div>
              )}
            </ImageWrapper>
          </Grid>
          <Grid
            background
            labelContent={t("Place of employment")}
            padding={"15px"}
            labelSpan={6}
            required
          >
            <PlaceOfEmploymentWrapper>
              <RadioGroup
                name={"place_of_employment"}
                value={values?.place_of_employment || null}
                options={PLACE_OF_EMPLOYMENT || []}
                onChange={({ target: { value } }) =>
                  setFieldValue("place_of_employment", value)
                }
              />
              <div className={"input-field"}>
                <InputField
                  name={"employment_place"}
                  value={values?.employment_place}
                  onChange={(val) =>
                    setFieldValue("employment_place", val.target.value)
                  }
                  placeholder={t("Please enter")}
                  size={"large"}
                  width={"224px"}
                  error={
                    formik?.touched?.employment_place &&
                    errors?.employment_place
                      ? errors?.employment_place
                      : null
                  }
                />
              </div>
              <div className={"date-picker"}>
                <span className={"title"}>{t("Employment date")}</span>
                <DatePicker
                  name={"employment_place_date"}
                  placeholder={t("Please select")}
                  date={
                    values?.employment_place_date
                      ? dayjs(values?.employment_place_date)
                      : null
                  }
                  onDateChange={(date: Dayjs) =>
                    setFieldValue(
                      "employment_place_date",
                      dayjs(date).format("YYYY-MM-DD"),
                    )
                  }
                  error={
                    !!formik?.touched?.employment_place_date &&
                    !!errors?.employment_place_date
                      ? errors?.employment_place_date
                      : null
                  }
                />
              </div>
            </PlaceOfEmploymentWrapper>
          </Grid>
          <Grid
            background
            labelContent={t("Status of user")}
            padding={"15px"}
            required
            labelSpan={6}
          >
            <RadioGroup
              name={"status_of_user"}
              value={values?.status_of_user || null}
              options={LAST_STATUS_OF_USER || []}
              onChange={({ target: { value } }) =>
                setFieldValue("status_of_user", +value)
              }
            />
          </Grid>
          <Grid
            background
            labelContent={t("Remarks")}
            padding={"15px"}
            required={values?.status_of_user === 4 ? true : false}
            labelSpan={6}
          >
            <InputAreaField
              rows={3}
              resize={false}
              placeholder={t("Please enter")}
              name={"remarks"}
              value={values?.remarks || null}
              onChange={({ target: { value } }) =>
                setFieldValue("remarks", value)
              }
              error={
                !!formik?.touched?.remarks && !!errors?.remarks
                  ? errors?.remarks
                  : null
              }
            />
          </Grid>
          <Grid
            background
            labelContent={t("Method of contact")}
            padding={"15px"}
            labelSpan={6}
          >
            <SelectInput
              name={"employment_date"}
              placeholder={t("Please select")}
              value={values?.method_of_contact || null}
              options={METHOD_OF_CONTACT || []}
              onChange={(val) => setFieldValue("method_of_contact", val)}
              error={
                formik?.touched?.method_of_contact && errors?.method_of_contact
                  ? t(`${errors?.method_of_contact}`)
                  : ""
              }
            />
          </Grid>
        </div>

        <Flex gap={8} wrap={"wrap"}>
          <Button
            btnText={t("Cancel")}
            shape={"round"}
            disabled={isSaving || isLoading}
            onClick={() => router.push("/users")}
          />
          <Button
            btnText={t("Save")}
            shape={"round"}
            type={"primary"}
            htmlType={"submit"}
            disabled={isLoading || isFileSubmitting}
            isLoading={isSaving}
            onClick={handleSubmit}
          />
        </Flex>
      </Flex>
      <br />
      <div ref={contentRef}>
        <EmploymentCertificateStatusList
          dataSource={response?.data || []}
          page={page}
          total={(response?.count as any) || 0}
          isLoading={isLoading || isFetching}
          handlePaginationChange={handlePaginationChange}
          refetch={refetch}
          refetchBasicInfo={refetchBasicInfo}
        />
      </div>
    </Card>
  )
}
